import React, { useState } from "react";
import {
  getAuth,
  inMemoryPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e: any) => {
    e.preventDefault();
    const auth = getAuth();

    setPersistence(auth, inMemoryPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password).then(
          (userCredential) => {
            navigate("/");
          }
        );
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div className="w-full  justify-center items-center h-screen">
      <form
        className="flex flex-col items-center justify-center h-screen text-[24px]"
        onSubmit={handleLogin}
      >
        <input
          type="text"
          placeholder="email"
          className="w-[50rem] p-4 rounded-md border-2 border-gray-200 focus:border-gray-500 focus:outline-none"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="password"
          className="w-[50rem] p-4 rounded-md border-2 border-gray-200 focus:border-gray-500 focus:outline-none"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="w-[50rem] p-4 rounded-md bg-gray-800 text-white">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;

import { arrayUnion, doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firestore } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

function Home() {
  const [introduce, setIntroduce] = useState<any>();
  const [tempintroduce, setTempIntroduce] = useState<any>({
    data: [{ year: 0, desc: [""] }],
  });
  const [business, setBusiness] = useState<any>();
  const [page, setPage] = useState(1);
  const [year, setYear] = useState("");
  const [content, setContent] = useState("");
  const [editText, setEditText] = useState("");
  const [selectedInput, setSelectedInput] = useState<null | number>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.location.href = "/login";
      }
    });
  }, []);

  useEffect(() => {
    const unsubscribeIntroduce = onSnapshot(
      doc(firestore, "introduce", "items"),
      (doc) => {
        try {
          const data = doc.data();
          if (data) {
            data.data.sort((a: any, b: any) => a.year - b.year);
            setIntroduce(data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    );

    const unsubscribeTempIntroduce = onSnapshot(
      doc(firestore, "tempintroduce", "items"),
      (doc) => {
        try {
          setTempIntroduce(doc.data());
        } catch (error) {
          console.log(error);
        }
      }
    );

    const unsubscribeBusiness = onSnapshot(
      doc(firestore, "majorBusiness", "items"),
      (doc) => {
        try {
          setBusiness(doc.data());
        } catch (error) {
          console.log(error);
        }
      }
    );

    return () => {
      unsubscribeIntroduce();
      unsubscribeBusiness();
      unsubscribeTempIntroduce();
    };
  }, []);

  useEffect(() => {
    console.log(editText);
  }, [editText]);

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const findIndex = introduce.data.findIndex(
        (item: any) => item.year === year
      );
      if (findIndex < 0) {
        //add data to firestore
        await updateDoc(doc(firestore, "introduce", "items"), {
          data: arrayUnion({
            year,
            desc: [content],
          }),
        });
      } else {
        const tempArr = { ...introduce };
        tempArr.data[findIndex].desc.push(content);
        setIntroduce(tempArr);
        await updateDoc(doc(firestore, "introduce", "items"), {
          ...tempArr,
        });
      }
    } catch (error) {
      console.log(error);
      window.alert("error");
    } finally {
      window.alert("success");
    }
  };

  const handleEditText = async (item: any, index: any, arr: any) => {
    const isYes = window.confirm("수정하시겠습니까?");
    if (isYes) {
      const tempArr = { ...introduce };
      const findIndex = tempArr.data.findIndex(
        (itemArr: any) =>
          itemArr.desc.length === arr.length && itemArr.desc[index] === item
      );
      console.log(findIndex); // data의 arry 여기서 index 로 이제 글씨를 찾아야함
      tempArr.data[findIndex].desc[index] = editText;

      await updateDoc(doc(firestore, "introduce", "items"), {
        ...tempArr,
      });
    }
  };

  const handleDelteText = async (item: any, index: any, arr: any) => {
    //show confirm , press yes -> delete , press no -> cancel
    const isDelete = window.confirm("정말 지우시겠습니까?");

    if (isDelete) {
      const tempArr = { ...introduce };
      const findIndex = tempArr.data.findIndex(
        (itemArr: any) =>
          itemArr.desc.length === arr.length && itemArr.desc[index] === item
      );
      tempArr.data[findIndex].desc.splice(index, 1);

      if (tempArr.data[findIndex].desc.length === 0) {
        tempArr.data.splice(findIndex, 1);
      }
      await updateDoc(doc(firestore, "introduce", "items"), {
        ...tempArr,
      });
    }
  };

  return (
    //make react login page

    <div className="flex w-full items-center justify-center flex-col p-10">
      <nav className="flex w-full justify-center gap-[4rem] text-[5rem]">
        <div className="cursor-pointer" onClick={() => setPage(1)}>
          <h1>보기</h1>
        </div>
        <div className="cursor-pointer" onClick={() => setPage(2)}>
          <h1>추가</h1>
        </div>
      </nav>
      {page === 1 && (
        <form className="flex w-full items-center justify-center flex-col p-10 ">
          {introduce &&
            introduce.data.map((item: any, index: any) => {
              return (
                <div key={index} className="w-full">
                  <h1 className="text-[5rem]">{item.year}</h1>

                  <div className="flex flex-col w-full">
                    {item.desc.map((item: any, index: any, arr: any) => {
                      const uniqueKey = arr.length + 1 * index + 1;

                      return (
                        <div key={index} className="flex w-full">
                          <input
                            key={index}
                            type="text"
                            className="text-[3rem] w-full"
                            placeholder={item}
                            onChange={(e) => setEditText(e.target.value)}
                            disabled={selectedInput !== uniqueKey}
                            defaultValue={item}
                          />
                          <div
                            className="bg-[#ddffb9] p-[2rem] cursor-pointer"
                            onClick={() => {
                              if (selectedInput !== uniqueKey) {
                                setSelectedInput(uniqueKey);
                              } else {
                                handleEditText(item, index, arr);
                                setSelectedInput(0);
                              }
                            }}
                          >
                            {selectedInput !== uniqueKey ? (
                              <h2 className="text-[2rem]">수정</h2>
                            ) : (
                              <h2 className="text-[2rem]">완료</h2>
                            )}
                          </div>
                          <div
                            className="bg-[#ff5c00] p-[2rem] cursor-pointer"
                            onClick={() => handleDelteText(item, index, arr)}
                          >
                            <h2 className="text-[2rem]">삭제</h2>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </form>
      )}

      {page === 2 && (
        <form className="text-[3rem]" onSubmit={handleOnSubmit}>
          <div className="flex items-center gap-[2rem]">
            <label>년도</label>
            <input
              placeholder="년도 입력(ex:2023)"
              maxLength={4}
              onChange={(e) => setYear(e.target.value)}
              value={year}
            />
          </div>
          <div className="flex items-center gap-[2rem]">
            <label>내용</label>
            <input
              placeholder="내용 입력"
              onChange={(e) => setContent(e.target.value)}
              value={content}
            />
          </div>
          <button type="submit" className=" bg-[#ff5c00]">
            제출
          </button>
        </form>
      )}
    </div>
  );
}

export default Home;
